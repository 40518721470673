import {
  ArtTrack,
  AutoAwesomeMotion,
  Bento,
  Dashboard,
  ManageSearch,
  Settings,
  SupportAgent,
  ViewList,
  Web,
  WorkspacePremium,
} from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, ClickAwayListener, Drawer } from '@mui/material';
import clsx from 'clsx';
import { SvgAdsClick } from 'components/svg-icons/ads-click';
import { APP_ROUTES } from 'configs';
import { useOpen, useTranslate } from 'hooks';
import React, { useMemo } from 'react';
import { Languages } from '../languages';
import { MenuLogo } from '../menu-logo';
import { Navigation } from '../navigation';
import style from './index.module.scss';

interface Props {}

export const Panel: React.FC<Props> = () => {
  const { tp } = useTranslate();

  const { isOpen, onToggle, onClose, onOpen } = useOpen();

  const options = useMemo(() => {
    return [
      {
        title: tp('dashboard'),
        Icon: <Dashboard color={'inherit'} />,
        to: APP_ROUTES.DASHBOARD().config,
      },
      {
        title: tp('home-page'),
        Icon: <Web color={'inherit'} />,
        to: APP_ROUTES.HOME_PAGE().config,
      },
      {
        title: tp('about'),
        Icon: <InfoOutlinedIcon color={'inherit'} />,
        to: APP_ROUTES.ABOUT().config,
      },
      {
        title: tp('services'),
        Icon: <ViewList color={'inherit'} />,
        to: APP_ROUTES.SERVICES().config,
      },
      {
        title: tp('blog-posts'),
        Icon: <Bento color={'inherit'} />,
        to: APP_ROUTES.BLOG_POSTS().config,
      },
      {
        title: tp('certificates'),
        Icon: <WorkspacePremium color={'inherit'} />,
        to: APP_ROUTES.CERTIFICATES().config,
      },
      {
        title: tp('gallery'),
        Icon: <ArtTrack color={'inherit'} />,
        to: APP_ROUTES.GALLERY().config,
      },
      {
        title: tp('atr-search-engine'),
        Icon: <ManageSearch color={'inherit'} />,
        to: APP_ROUTES.ATR_SEARCH_ENGINE().config,
      },
      {
        title: tp('parts'),
        Icon: <AutoAwesomeMotion color={'inherit'} />,
        to: APP_ROUTES.PARTS().config,
      },
      {
        title: tp('contact'),
        Icon: <SupportAgent color={'inherit'} />,
        options: [
          {
            title: tp('contact-us'),
            to: APP_ROUTES.CONTACT_US().config,
          },
          {
            title: tp('thank-you-page'),
            to: APP_ROUTES.THANK_YOU_PAGE().config,
          },
        ],
      },
      {
        title: tp('leads'),
        Icon: <SvgAdsClick color={'inherit'} />,
        to: APP_ROUTES.LEADS().config,
      },
      {
        title: tp('settings'),
        Icon: <Settings color={'inherit'} />,
        options: [
          {
            title: tp('cms-users'),
            to: APP_ROUTES.CMS_USERS().config,
          },
          {
            title: tp('permissions'),
            to: APP_ROUTES.PERMISSIONS().config,
          },
          {
            title: tp('labels'),
            to: APP_ROUTES.LABELS().config,
          },
          {
            title: tp('system-settings'),
            to: APP_ROUTES.SYSTEM_SETTINGS().config,
          },
        ],
      },
    ];
  }, [tp]);

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Drawer
        open={isOpen}
        variant={'permanent'}
        onClose={onClose}
        classes={{
          paper: clsx(style.paper, isOpen && style.paperOpen),
        }}
      >
        <Box className={style.inner}>
          <MenuLogo isOpen={isOpen} onToggle={onToggle} />
          <Box>
            <Navigation
              isMenuOpen={isOpen}
              onMenuOpen={onOpen}
              onMenuClose={onClose}
              options={options}
            />
          </Box>
          <Languages sx={{ mb: 4 }} />
        </Box>
      </Drawer>
    </ClickAwayListener>
  );
};
