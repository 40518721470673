import { Box, Typography } from '@mui/material';
import { useTranslate } from 'hooks';
import logo from 'images/logo.svg';
import { memo } from 'react';
import { Outlet } from 'react-router-dom';

import style from './index.module.scss';

export const LoginLayout = memo(() => {
  const { t } = useTranslate();

  return (
    <Box className={style.wrap}>
      <Box className={style.headerWrapper}></Box>
      <Box className={style.content}>
        <Box className={style.form}>
          <img className={style.logo} src={logo} alt={''} />
          <Outlet />
        </Box>
      </Box>
      <Box className={style.footer}>
        <Typography component={'div'} variant={'caption'} textAlign={'center'}>
          {t('copyright')}
        </Typography>
      </Box>
    </Box>
  );
});
