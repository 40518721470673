const API = 'LabelSources';

export const API_LABEL_SOURCES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<LabelSource, 'id'>) => `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<LabelSource, 'id'>) => `${API}/Delete/${data.id}`,
};
export enum LABEL_SOURCE_KEYS {
  CMS = 'cms',
  WEBSITE = 'website',
}

export enum LABEL_SOURCE_ID {
  CMS = '8bf8e3a7-8903-4daf-892f-48a63f867c82',
  WEBSITE = 'd7a16bab-db53-4d45-90a6-d9fdb9b1a6fa',
}
export interface LabelSource {
  id: string;
  title: string;
  key: LABEL_SOURCE_KEYS;
}
export interface ILabelSource extends LabelSource {
  labelSource: ILabelSource;
}
