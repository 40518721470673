import { Box } from '@mui/material';
import { TabsPage } from 'components/tabs-page';
import { APP_ROUTES } from 'configs';
import { useTranslate } from 'hooks';
import { memo, useMemo } from 'react';
import style from './index.module.scss';

export const ContactsLayout = memo(() => {
  const { tp } = useTranslate();
  const tabs = useMemo(() => {
    return [
      {
        value: APP_ROUTES.CONTACT_US.path,
        to: APP_ROUTES.CONTACT_US().config,
        label: tp('contact-us'),
      },
      {
        value: APP_ROUTES.THANK_YOU_PAGE.path,
        to: APP_ROUTES.THANK_YOU_PAGE().config,
        label: tp('thank-you-page'),
      },
    ];
  }, [tp]);
  return (
    <>
      <Box className={style.root}>
        <Box className={style.inner}>
          <TabsPage tabs={tabs} />
        </Box>
      </Box>
    </>
  );
});
