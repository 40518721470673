import React, { useCallback } from 'react';

import { List, ListItemButton, ListItemText, ListProps } from '@mui/material';
import clsx from 'clsx';
import { useAppDispatch, useDetectLanguage, useSourceLanguages } from 'hooks';
import { actionAccountSetLanguageID } from 'store/auth';
import style from './index.module.scss';

interface Props extends ListProps {}

export const Languages: React.FC<Props> = ({ className, ...rest }) => {
  const dispatch = useAppDispatch();
  const { data } = useSourceLanguages();
  const { languageID } = useDetectLanguage();
  const factoryChangeLanguage = useCallback(
    (languageID: string) => {
      return () => dispatch(actionAccountSetLanguageID(languageID));
    },
    [dispatch],
  );
  return (
    <List {...rest} className={clsx(style.root, className)}>
      {data.map((lang) => {
        return (
          <ListItemButton
            selected={languageID === lang.id}
            key={lang.id}
            onClick={factoryChangeLanguage(lang.id)}
            sx={{ px: 0 }}
          >
            <ListItemText sx={{ textAlign: 'center', color: 'white' }}>{lang.title}</ListItemText>
          </ListItemButton>
        );
      })}
    </List>
  );
};
