import { contains, dynamicNamespace, mergeFilters } from 'utils/dynamic';
import {
  apiRtk,
  behaviourMoveRows,
  decoratorRank,
  decoratorWithFiles,
  DynamicResult,
  DynamicService,
} from 'utils/service';
import { PatchPartial } from 'utils/types';
import { API_LANGUAGES, IGridLanguage, ILanguage, Language } from './models';

const REVALIDATE_KEY = 'Languages' as const;

const dynamic = dynamicNamespace<ILanguage>();

export * from './models';

class Service extends DynamicService<Language> {
  @decoratorWithFiles('id', 'icon')
  @decoratorRank('rank')
  async post(data: Language) {
    return super.post(data);
  }

  @decoratorWithFiles('id', 'icon')
  async patch(data: Partial<Language>) {
    return super.patch(data);
  }

  moveRows = async function (newRows: Partial<Language>[], oldRows: Partial<Language>[]) {
    return behaviourMoveRows({
      mainField: 'id',
      moveField: 'rank',
      newRows,
      oldRows,
      requestPatch: ServiceLanguages.patch,
    });
  };
}

export const ServiceLanguages = new Service({
  getAll: API_LANGUAGES.GET_ALL_DYNAMIC,
  post: API_LANGUAGES.CREATE,
  patch: API_LANGUAGES.PATCH,
  delete: API_LANGUAGES.DELETE,
});

type ApiModel = Language;

export const apiLanguages = apiRtk.injectEndpoints({
  endpoints: (build) => ({
    getAllLanguages: build.query<ApiModel[], void>({
      queryFn: async () => {
        try {
          const {
            data: { value },
          } = await ServiceLanguages.getAllDynamic({
            orderBy: dynamic.orderBy('rank', 'asc'),
          });
          return { data: value };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: () => [{ type: REVALIDATE_KEY }],
    }),
    getGridLanguages: build.query<
      DynamicResult<IGridLanguage, { count: true }>,
      {
        search: string;
        order: { field: string; order: 'asc' | 'desc' | null };
        take: number;
        skip: number;
      }
    >({
      queryFn: async ({ search, order, take, skip }) => {
        try {
          const params = {
            filter: mergeFilters(dynamic.makeFilter(['title'], search, contains)).join('&&'),
            select: dynamic.select('id', 'rank', 'culture', 'title', 'isActive'),
            orderBy: dynamic.orderBy(order.field as keyof IGridLanguage, order.order),
            take,
            skip,
            count: true,
          };
          const { data } = await ServiceLanguages.getAllDynamic<IGridLanguage, typeof params>(
            params,
          );
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: [{ type: REVALIDATE_KEY }],
    }),
    getLanguage: build.query<ApiModel, string>({
      queryFn: async (id) => {
        try {
          const { data } = await ServiceLanguages.getDynamic(id);
          return { data };
        } catch (e: any) {
          return { error: e };
        }
      },
      providesTags: (result, error, id) => [{ type: REVALIDATE_KEY, id }],
    }),
    postLanguage: build.mutation<void, ApiModel>({
      queryFn: async (data) => {
        try {
          await ServiceLanguages.post(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: [{ type: REVALIDATE_KEY }],
    }),
    patchLanguage: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceLanguages.patch(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    deleteLanguage: build.mutation<void, PatchPartial<ApiModel, 'id'>>({
      queryFn: async (data) => {
        try {
          await ServiceLanguages.delete(data);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: REVALIDATE_KEY },
        { type: REVALIDATE_KEY, id },
      ],
    }),
    moveLanguages: build.mutation<
      void,
      { newRows: Partial<ApiModel>[]; oldRows: Partial<ApiModel>[] }
    >({
      queryFn: async ({ newRows, oldRows }) => {
        try {
          await ServiceLanguages.moveRows(newRows, oldRows);
          return { data: undefined };
        } catch (e: any) {
          return { error: e };
        }
      },
      invalidatesTags: (result, error, { newRows }) => [
        { type: REVALIDATE_KEY },
        ...newRows.map((item) => ({ type: REVALIDATE_KEY, id: item.id })),
      ],
    }),
  }),
});
