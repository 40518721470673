import { AppLoading } from 'components/app-loading';
import { APP_ROUTES } from 'configs';
import { ContactsLayout, EmptyLayout, LabelsLayout, LoginLayout, PrivateLayout } from 'layouts';
import React from 'react';
import { RouteObject } from 'react-router-dom';
import { RequireAuth } from 'router/requred-auth';
import { LABEL_SOURCE_ID } from '../services/label-sources';

const DashboardPage = React.lazy(() => import('pages/dashboard'));
const PartsPage = React.lazy(() => import('pages/parts'));

const HomePage = React.lazy(() => import('pages/common-pages/component/pages/homepage'));
const AboutPage = React.lazy(() => import('pages/common-pages/component/pages/about'));
const ServicesPage = React.lazy(() => import('pages/common-pages/component/pages/services'));
const BlogPostsPage = React.lazy(() => import('pages/common-pages/component/pages/blog-posts'));
const CertificatesPage = React.lazy(
  () => import('pages/common-pages/component/pages/certificates'),
);
const GalleryPage = React.lazy(() => import('pages/common-pages/component/pages/gallery'));
const ArtSearchEnginePage = React.lazy(
  () => import('pages/common-pages/component/pages/atr-search-engine'),
);
const ContactUsPage = React.lazy(() => import('pages/common-pages/component/pages/contact'));
const ThankTouPage = React.lazy(() => import('pages/common-pages/component/pages/thanks'));

const LeadsPage = React.lazy(() => import('pages/leads'));

const ProfilePage = React.lazy(() => import('pages/profile'));
const CmsUsersPage = React.lazy(() => import('pages/cms-profiles'));
const PermissionsPage = React.lazy(() => import('pages/cms-permissions'));
const UserCmsItemPage = React.lazy(() => import('pages/user-cms-profile-item'));

const LabelsContainer = React.lazy(() => import('pages/labels/labels-container'));
const LanguagesPage = React.lazy(() => import('pages/labels/languages'));
const SystemSettingsPage = React.lazy(() => import('pages/system-settings'));

const LoginPage = React.lazy(() => import(/* webpackChunkName: "login" */ 'pages/login'));
const LoginCodePage = React.lazy(() => import('pages/login-code'));

const NotFoundPage = React.lazy(() => import('pages/todo'));

export const routes: RouteObject[] = [
  {
    element: (
      <RequireAuth>
        <PrivateLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: APP_ROUTES.DASHBOARD.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <DashboardPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.HOME_PAGE.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <HomePage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.ABOUT.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <AboutPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.SERVICES.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <ServicesPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.BLOG_POSTS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <BlogPostsPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.CERTIFICATES.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <CertificatesPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.GALLERY.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <GalleryPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.ATR_SEARCH_ENGINE.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <ArtSearchEnginePage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PARTS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PartsPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.CONTACT.path,
        element: <ContactsLayout />,
        children: [
          {
            path: APP_ROUTES.CONTACT_US.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <ContactUsPage />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.THANK_YOU_PAGE.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <ThankTouPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: APP_ROUTES.LEADS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <LeadsPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PROFILE.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <ProfilePage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.CMS_USERS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <CmsUsersPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.PERMISSIONS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <PermissionsPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.USER_ITEM.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <UserCmsItemPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.LABELS.path,
        element: <LabelsLayout />,
        children: [
          {
            index: true,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <LabelsContainer
                  labelSourceID={LABEL_SOURCE_ID.WEBSITE}
                  gridName={'labelWebsite'}
                />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.CMS.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <LabelsContainer labelSourceID={LABEL_SOURCE_ID.CMS} gridName={'labelCms'} />
              </React.Suspense>
            ),
          },
          {
            path: APP_ROUTES.LANGUAGES.path,
            element: (
              <React.Suspense fallback={<AppLoading />}>
                <LanguagesPage />
              </React.Suspense>
            ),
          },
        ],
      },
      {
        path: APP_ROUTES.SYSTEM_SETTINGS.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <SystemSettingsPage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    element: <LoginLayout />,
    children: [
      {
        path: APP_ROUTES.LOGIN.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <LoginPage />
          </React.Suspense>
        ),
      },
      {
        path: APP_ROUTES.LOGIN_CODE.path,
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <LoginCodePage />
          </React.Suspense>
        ),
      },
    ],
  },
  {
    element: <EmptyLayout />,
    children: [
      {
        path: '*',
        element: (
          <React.Suspense fallback={<AppLoading />}>
            <NotFoundPage />
          </React.Suspense>
        ),
      },
    ],
  },
];
