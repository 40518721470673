import React from 'react';

import style from './index.module.scss';

import { Box, Container, Stack } from '@mui/material';
import clsx from 'clsx';
import { ButtonGoTo } from 'components/buttons';
import { APP_ROUTES } from 'configs';
import { HEADER_PORTAL_ID } from 'configs/const';
import { useTranslate } from 'hooks';
import { useMatch } from 'react-router-dom';
import { HeaderProfile } from '../header-profile';

interface Classes {
  root: string;
}

interface Props {
  className?: string;
  classes?: Partial<Classes>;
}

export const Header: React.FC<Props> = ({ className, classes }) => {
  const { tp } = useTranslate();
  const isDashboard = useMatch(APP_ROUTES.DASHBOARD.path);
  const isPartsList = useMatch(APP_ROUTES.PARTS.path);
  return (
    <Box className={clsx(style.root, classes?.root, className)}>
      <Container maxWidth={false}>
        <Box className={style.inner}>
          <Box id={HEADER_PORTAL_ID} />
          <Stack direction={'row'} spacing={1} alignItems={'center'}>
            {!isDashboard && (
              <Box>
                <ButtonGoTo to={APP_ROUTES.DASHBOARD().config}>{tp('dashboard')}</ButtonGoTo>
              </Box>
            )}
            {!isPartsList && (
              <Box>
                <ButtonGoTo to={APP_ROUTES.PARTS().config}>{tp('parts-list')}</ButtonGoTo>
              </Box>
            )}
            <Box pl={1}>
              <HeaderProfile />
            </Box>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};
