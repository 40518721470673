import { useMemo } from 'react';
import { apiLabelSources } from 'services/label-sources';
import { apiLanguages } from 'services/languages';
import { apiUserCmsProfilePermissions } from 'services/user-cms-profile-permissions';
import { ArrayType } from 'utils/types';
import { useMap } from './use-map';
import { useTranslate } from './use-translate';

type DataType<T> = T extends { data?: infer U } ? U : unknown;

export const useSourceData = <T extends { data?: any[] }>(v: T) => {
  const data = useMemo(() => {
    return (v.data || []) as Exclude<DataType<T>, undefined>;
  }, [v.data]);
  return useMemo(() => ({ ...v, data }), [v, data]);
};
export const useSourceDataMap = <T extends { data: any[] }>(
  v: T,
  mapKey: keyof ArrayType<T['data']>,
) => {
  const map = useMap<ArrayType<T['data']>>(v.data, String(mapKey));
  return useMemo(() => ({ ...v, map }), [v, map]);
};
const useSourceTranslate = <T extends { data: any[] }>(
  v: T,
  translateKey: keyof ArrayType<T['data']>,
) => {
  const { t } = useTranslate();

  const data = useMemo(() => {
    return v.data.map((item) => ({ ...item, title: t(item[translateKey]) }));
  }, [v.data, t, translateKey]);
  return useMemo(() => {
    const { data: _, ...rest } = v;
    return {
      ...rest,
      data: data as (ArrayType<T['data']> & { title: string })[],
    };
  }, [v, data]);
};
export const useSourceCmsPermissions = () => {
  return useSourceData(apiUserCmsProfilePermissions.useGetUserCmsProfilePermissionSourceQuery());
};
export const useSourceLabelSources = () => {
  return useSourceTranslate(useSourceData(apiLabelSources.useGetAllLabelSourcesQuery()), 'key');
};
export const useSourceLanguages = () => {
  return useSourceData(apiLanguages.useGetAllLanguagesQuery());
};
