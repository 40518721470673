import { createTypedRoute } from 'modules/typed-routes';

export const APP_ROUTES = {
  DASHBOARD: createTypedRoute('/'),
  HOME_PAGE: createTypedRoute('/home-page'),
  ABOUT: createTypedRoute('/about'),
  SERVICES: createTypedRoute('/services'),
  BLOG_POSTS: createTypedRoute('/blog-posts'),
  CERTIFICATES: createTypedRoute('/certificates'),
  GALLERY: createTypedRoute('/gallery'),
  ATR_SEARCH_ENGINE: createTypedRoute('/atr-search-engine'),
  PARTS: createTypedRoute('/parts'),
  CONTACT: createTypedRoute('/contact'),
  CONTACT_US: createTypedRoute('/contact/contact-us'),
  THANK_YOU_PAGE: createTypedRoute('/contact/thank-you-page'),
  LEADS: createTypedRoute('/leads'),
  SETTINGS: createTypedRoute('/settings'),
  CMS_USERS: createTypedRoute('/settings/cms-users'),
  PERMISSIONS: createTypedRoute('/settings/permissions'),
  USER_ITEM: createTypedRoute<{ appUserID: string | 'new' }>('/settings/users/:appUserID'),

  LABELS: createTypedRoute('/settings/labels'),
  CMS: createTypedRoute('/settings/labels/cms'),
  LANGUAGES: createTypedRoute('/settings/labels/languages'),

  SYSTEM_SETTINGS: createTypedRoute('/settings/system-settings'),
  PROFILE: createTypedRoute('/profile'),

  LOGIN: createTypedRoute<{ redirect?: string }>('/login'),
  LOGIN_CODE: createTypedRoute<{ email: string; redirect?: string }>('/login/:email'),
};
