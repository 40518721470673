import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Label } from 'services/labels';
import { actionLabelsChangeItem, actionLabelsGetDynamic } from './actions';

interface Filters {
  search: string;
  languageID: string;
  sourceID: string;
}
interface Pagination {
  take: number;
  count: number;
  page: number;
}
interface OrderBy {
  field: string;
  order: 'desc' | 'asc' | null;
}
export interface IGridLabel extends Pick<Label, 'id' | 'labelKey' | 'title' | 'isActive'> {
  languageTitle: string;
}

interface State {
  error: null | Error;
  isLoading: boolean;
  isInit: boolean;
  data: IGridLabel[];
  filters: Filters;
  pagination: Pagination;
  orderBy: OrderBy;
}

const initState = (): State => {
  return {
    error: null,
    isLoading: false,
    isInit: false,
    data: [],
    filters: {
      search: '',
      languageID: '',
      sourceID: '',
    },
    pagination: {
      take: 20,
      count: 0,
      page: 1,
    },
    orderBy: {
      field: 'labelKey',
      order: 'desc',
    },
  };
};

const slice = createSlice({
  name: 'LABELS',
  initialState: initState(),
  reducers: {
    actionLabelsFiltersSet(state, action: PayloadAction<Partial<Filters>>) {
      state.filters = { ...state.filters, ...action.payload };
    },
    actionLabelsFiltersReset(state) {
      state.filters = initState().filters;
    },
    actionLabelsOrderBySet(state, action: PayloadAction<Partial<OrderBy>>) {
      state.orderBy = { ...state.orderBy, ...action.payload };
    },
    actionChangeSourceID(state, action) {
      state.isInit = false;
      state.filters = { ...state.filters, sourceID: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionLabelsChangeItem.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(actionLabelsChangeItem.fulfilled, (state) => {
      state.isLoading = false;
      state.isInit = true;
    });
    builder.addCase(actionLabelsChangeItem.rejected, (state, action) => {
      const { error } = action;
      state.isLoading = false;
      state.error = error;
    });

    builder.addCase(actionLabelsGetDynamic.pending, (state, action) => {
      state.isLoading = true;
      state.error = null;
      state.pagination.page = action.meta.arg ? action.meta.arg.page : state.pagination.page;
    });
    builder.addCase(actionLabelsGetDynamic.fulfilled, (state, action) => {
      const {
        payload: { value, count },
      } = action;
      state.isLoading = false;
      state.isInit = true;
      state.data = value;
      state.pagination.count = count;
    });
    builder.addCase(actionLabelsGetDynamic.rejected, (state, action) => {
      const { error } = action;
      state.isLoading = false;
      state.error = error;
      state.isInit = true;
    });
  },
});

export const { actionLabelsOrderBySet, actionLabelsFiltersSet, actionChangeSourceID } =
  slice.actions;
export const reducerLabels = slice.reducer;
